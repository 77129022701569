.footer-container {
    margin-top: 2vh;
    background-color: #FAFAFA;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;


    .powered-by {
        color: #71717A;
        background-color: #FAFAFA;
        font-weight: 700;
        margin: 32px 0px 16px 0px;
        text-align: center;
        
        a {
            text-decoration: none;
            color: $primary-purple;
            outline: 0;
        }
    }

    .hr-footer-divider {
        width: 50%;
        height: 1px;
        background-color: #E2E8F0;
        border: none;
    }

    .copyright {
        margin-top: 1vh;
        text-align: center;
        color: #94A3B8;
        margin: 16px 5px 32px 5px;
    }
}