
$primary-black: #1E293B;
$primary-purple: #6366F1;
$primary-gray: #94A3B8;

@import '../src/components/Container/Container.scss';
@import '../src/components/Form/Form.scss';
@import '../src/components/Input/Input.scss';
@import '../src/components/InputRadius/InputRadius.scss';
@import '../src/components/Footer/Footer.scss';

.app {
  background-color: #F0F4F9;
  min-height: 100vh;
  font-family: 'Space Grotesk';
}

.title {
  font-size: 30px;
  font-weight: bold;
}

.divider {
  margin-top: 5vh;
  border: 0;
  height: 0;
  border: 1px solid #CBD5E1;
  width: 100%;
}

.add-file-button {
  border: 1px solid #6366F1;
  padding: 8px 16px;
  border-radius: 4px;
  max-width: 150px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F0F4F9;
  color: #6366F1;
  cursor: pointer;

  span {
    font-size: 18px;
    margin-right: 5px;
  }
}


.container-pb {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
  .publish-blockchain {
    background-color: $primary-purple;
    width: 219px;
    height: 39px;
    outline: none;
    box-sizing: border-box;
    border: none;
    border-radius: 4px;
    span {
      color: white;
      font-family: 'Space Grotesk';
      font-size: 18px;
    }
    &:hover {
      cursor: pointer;
      background-color: #3e41e9;
      transition: all 0.3s ease;
    }
  }
}

.sbom-hash {
  display: flex;
  flex-direction: column;
  .hash-text-area {
      resize: none;
      outline: none;
      border: none;
      border-radius: 4px;
      box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
      padding: 10px 15px;
      font-family: 'Space Grotesk';
      font-size: 16px;
      margin-top: 5px;
    
    &-disabled {
      resize: none;
      outline: none;
      border: none;
      border-radius: 4px;
      box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
      padding: 10px 15px;
      font-family: 'Space Grotesk';
      font-size: 16px;
      margin-top: 5px;
      opacity: 0.7;
    }
  }
}

.hash-text-input {
  outline: none;
  border: none;
  border-radius: 4px;
  box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
  font-family: 'Space Grotesk';
  font-size: 16px;
  margin-top: 5px;

  &-disabled {
    outline: none;
    border: none;
    border-radius: 4px;
    box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    padding: 10px 15px;
    font-family: 'Space Grotesk';
    font-size: 16px;
    margin-top: 5px;
    opacity: 0.7;
  }
}

.hash-type {
  .hash-type-boxes {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: start;
      flex-wrap: wrap;
      button {
          margin-right: 0.5vw;
      }
  }
}

.blockchain-explorer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  textarea {
    resize: none;
    outline: none;
    border: none;
    box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    width: 554px;
    height: 40px;
    border-radius: 4px;
    margin: 10px 0px 10px 0px;
  }
}

.json-container {
  margin-top: 16px;
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.token-id-label {
  font-size: 18px;
  margin-top: 30px;
  font-weight: 700;
}
.pre-container {
  background-color: rgb(34, 34, 34);
  border-radius: 15px;
  color: #00DD00;
  padding: 20px;
  min-height: 80vh;
  width: 100%;
  position: sticky;
  top: 20px;

  font-size: 18px;
  max-width: 700px;
  white-space: pre-wrap;       
  white-space: -moz-pre-wrap;  
  white-space: -pre-wrap;     
  white-space: -o-pre-wrap;
  word-wrap: break-word;       
}