.form {
    .parent-sbom-container {

        .radius-container {
            margin: 20px 0;

            &:last-child {
                margin-bottom: 0;
            }
            
            
            .input-label-container {
                margin-top: 10px;
                margin-bottom: 5px;
                display: flex;
                align-items: center;
                  
                label {
                    font-size: 20px;
                }
            }

            

        }
        .add-buttons-container {
            display: flex;
            align-items: center;
            margin-top: 30px;
            button {
                margin-right: 10px;
                span {
                    font-size: 1.25em;
                    margin-right: 5px;
                }
            }
        }

    }

    .current-sbom-container {
        margin-top: 50px;
    }
}

.parent-blockchain-url {
    margin-top: 20px;
}

.actions-aditionals {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tiny-label {
    display: block;
    font-size: 16px;
    padding: 6px 0;
}

.hash-button {
    max-width: 100%;
    display: flex;
    padding: 0.75vw 1vw;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    box-sizing: border-box;
    box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    span {  
        font-size: 20px;
        font-family: 'Space Grotesk';
    }
    cursor: pointer;
    
    &-active {
        background: #E0E7FF;
        border: 1px solid #6366F1;
        box-sizing: border-box;
        box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
        max-width: 100%;
        display: flex;
        padding: 0.75vw 1vw;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        span {
            font-size: 20px;
            font-family: 'Space Grotesk';
        }
        cursor: pointer;
            }
}

.additional-files {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    p {
        font-size: 20px;
    }
}


.parent-sbom-hash {
    transition: all 5s ease-in;    

    .sbom-hash-card {
        border-radius: 4px;
        box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
        max-height: 250px;
        background-color: white;
        padding: 16px;
        margin-bottom: 16px;
    }
}
