.container {
    padding: 1vh 10vw;

    .title-container {
        margin-bottom: 50px;

        .title-text {
            font-size: 46px;
            font-weight: bold;
            color: $primary-black;
        }
    }

    .main-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        grid-gap: 5vw;
    }

    .alert {
        top: 10px;
        position: relative;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        z-index: 1000;
        height: 5vh;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-radius: 5px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);

        div {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        span {
            font-size: 20px;
        }

        &-active-uploading {
            transition: opacity 0.5s;
            z-index: 1000;
            opacity: 0.7;
            background-color: #ffffff;
            height: 10vh;
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            border-radius: 5px;
            box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
            span {
                font-size: 20px;
            }
            
            .container-message-spinner {
                display: flex;
                align-items: center;
                .spinner {
                    border: 4px solid rgba(0, 0, 0, 0.1);
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    border-left-color: indigo;
                  
                    animation: spin 1s ease infinite;
                  }
                  
                  @keyframes spin {
                    0% {
                      transform: rotate(0deg);
                    }
                  
                    100% {
                      transform: rotate(360deg);
                    }
                  }
                  span {
                      margin-left: 20px;
                  }
            }
        }
        
        &-active {
            transition: opacity 0.5s;
            z-index: 1000;
            opacity: 0.7;
            background-color: #ffffff;
            box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
            height: 10vh;
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            border-radius: 5px;
            span {
                font-size: 20px;
            }
            
        div {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        }

        &-active-error {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            transition: opacity 0.5s;
            z-index: 1000;
            opacity: 0.7;
            background-color: #ffff;
            border: 4px solid #ec2a3a;
            height: 10vh;
            width: 100%;
            border-radius: 5px;
            span {
                font-size: 20px;
                color: #ec2a3a;
            }
            
        div {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        }

        
   
    }

}
